import "alpinejs"

import "$styles/index.scss"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

import images from '../assets/**/*.{jpg,jpeg,png,svg}'
Object.entries(images).forEach(image => image)

import * as bootstrap from "node_modules/bootstrap"
window.bootstrap = bootstrap

import Typed from "node_modules/typed.js"
window.Typed = Typed

import "../assets/js/hs.core.js"
import "../assets/js/hs.typed.js"
import Alpine from "alpinejs"

HSCore.init()
Alpine.start()
