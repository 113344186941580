
        import * as module0 from '../assets/img/europdf-og.png';import * as module1 from '../assets/img/favicon.svg';import * as module2 from '../assets/img/mo.png';import * as module3 from '../assets/img/no-sales-team.png';import * as module4 from '../assets/img/signatures.png';import * as module5 from '../assets/img/stefan.png';import * as module6 from '../assets/svg/EuroPDF-white.svg';import * as module7 from '../assets/svg/EuroPDF.svg'
        const modules = {
            "../assets/img/europdf-og.png": module0,
            "../assets/img/favicon.svg": module1,
            "../assets/img/mo.png": module2,
            "../assets/img/no-sales-team.png": module3,
            "../assets/img/signatures.png": module4,
            "../assets/img/stefan.png": module5,
            "../assets/svg/EuroPDF-white.svg": module6,
            "../assets/svg/EuroPDF.svg": module7,
        };
        export default modules;
      